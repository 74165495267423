<template>
  <div id="app">
    <div class="uk-container uk-container-expand uk-padding-remove">
      <header-template></header-template>
      <content-template></content-template>
      <footer-template></footer-template>
    </div>
  </div>
</template>

<script>
import headerTemplate from "./components/Header";
import footerTemplate from "./components/Footer";
import contentTemplate from "./components/Content";

export default {
  name: "App",
  components: {
    headerTemplate,
    footerTemplate,
    contentTemplate,
  },
};
</script>

<style>
@import 'assets/fonts/gilroy.css';
*, h1, h2, h3, h4, h5, p, a {
  font-family: gilroy, sans-serif;
}

.bg-dark {
  background-color: #f5f5f5;
}

.green,
.green:hover {
  color: #7f2f44;
}
.green-button {
  background-color:  #7f2f44;
  color: #fff;
  font-weight: bold;
}

.radius-button {
  border-radius: 30px;
}

.uk-input,
.uk-input:focus {
  border-color: #7f2f44;
}

.uk-form-danger {
  border-color: #f0506e !important;
}

</style>
