<template>
  <div>
    <div
      class="uk-container uk-container-expand footer-background uk-padding-large"
    >
      <div class="uk-container footer__form-conatin uk-padding-small">
        <div class="uk-grid-small  uk-padding-small" uk-grid>
          <div class="uk-width-1-3@m uk-width-1-1@s uk-margin-small-right">
            <img
              class="uk-align-center uk-align-right@m"
              src="../assets/icons/mail.png"
              alt=""
            />
          </div>
          <div
            class="uk-width-auto uk-align-center uk-align-right@m uk-margin-small uk-margin-remove-top"
          >
            <p class="uk-text-bold uk-margin-remove footer__footer-title">
              Хотите узнать первыми о наших<br />
              новостях и акциях?
            </p>
            <p class="uk-margin-remove">Подписывайтесь на нашу рассылку!</p>
          </div>
        </div>
        <div
          class="uk-grid-small uk-padding-small uk-flex-center"
          uk-grid
          id="form"
        >
          <div class="uk-width-auto@m uk-width-1-1@s">
            <input
              v-model="agency"
              class="uk-input"
              type="text"
              name="sub_agency"
              placeholder="Наименование партнера"
              :class="{ 'uk-form-danger': !isValid && !!!agency }"
            />
          </div>
          <div class="uk-width-auto@m uk-width-1-1@s">
            <input
              v-model="phone"
              class="uk-input"
              type="text"
              name="sub_phone"
              placeholder="Телефон"
              :class="{ 'uk-form-danger': !isValid && !!!phone }"
            />
          </div>
          <div class="uk-width-auto@m uk-width-1-1@s">
            <input
              v-model="email"
              class="uk-input"
              type="text"
              name="sub_email"
              placeholder="E-mail"
              :class="{ 'uk-form-danger': !isValid && !!!email }"
            />
          </div>
          <div class="uk-width-auto@m uk-width-1-1@s">
            <button
              @click="subscribe"
              class="uk-button uk-button-default uk-align-center green-button"
            >
              Отправить
            </button>
          </div>
        </div>
        <div class="uk-grid-small uk-padding-small uk-flex-center" uk-grid>
          <p>
            Нажимая кнопку "Отправить" вы даете согласие на обработку
            <a
              href="https://vevanta.com/politika-konfidencialnosti"
              target="_blank"
              class="green"
            >
              персональных данных</a
            >
            и получение рассылок о новых предложениях и акциях
          </p>
        </div>
      </div>
    </div>
    <div class="uk-container uk-margin-small">
      <div
        class="uk-grid uk-flex-around uk-margin-medium-top uk-text-center uk-text-left@m uk-padding-small"
      >
        <div class="uk-width-1-1 uk-width-auto@l uk-margin-bottom">
          <a href="https://xn--80abvioqhp.xn--p1ai/"
            ><img class="uk-align-center" src="../assets/img/logo.png"
          /></a>
        </div>
        <div class="uk-width-1-1 uk-width-expand@l uk-margin-bottom ">
          ©2020-{{new Date().getFullYear()}} Компания «Брусинка». Все права защищены. При использовании
          материалов гиперссылка обязательна.
        </div>
        <div class="uk-width-1-1 uk-width-1-4@l uk-margin-bottom">
          г.Тюмень, ул. Железнодорожная 54/3<br />
          <a href="tel:+73452596041" class="green">+7 (3452) 59-60-41</a>
        </div>
        <div>
          <div class="uk-margin-small-bottom">
            Социальные сети
          </div>
          <div class="uk-margin-small-bottom uk-text-center">
            <a
              href="https://vk.com/stroitelstvo_domov_i_kottedjey"
              class="uk-margin-small-right"
              target="_blank"
            >
              <img src="../assets/img/vkontakte.png" height="20px" width="20px"
            /></a>
            <a
              href="https://www.instagram.com/vevanta72"
              class="uk-margin-small-right"
              target="_blank"
              ><img src="../assets/img/instagram.png" height="20px" width="20px"
            /></a>
            <a
              href="https://www.youtube.com/channel/UCRytU0cHs-IZN0dsBZW6Shw"
              class="uk-margin-small-right"
              ><img src="../assets/img/youtube.png" height="20px" width="20px"
            /></a>
          </div>
        </div>
      </div>

      <div class="uk-text-center ">
        <a
          href="https://xn--80abvioqhp.xn--p1ai/politika-konfidencialnosti"
          class="green"
          target="_blank"
          >Политика конфиденциальности</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import mixin from '../mixins/index';

export default {
  mixins: [mixin],
  data() {
    return {
      agency: "",
      phone: "",
      email: "",
      isValid: true,
    };
  },
  watch: {
    phone(val) {
      this.phoneMask(val, "phone");
    }
  },
  computed: {
    validForm() {
      return !(!this.email || !this.agency || !this.phone);
    },
  },
  methods: {
    subscribe() {
      if (!this.validForm) {
        this.isValid = false;
        return;
      }
      this.isValid = true;
      this.$axios
        .post("mail/vevanta_partner", {
          form: "Подписка на рассылку Брусинка партнеры",
          name: this.agency,
          phone: this.phone,
          email: this.email,
          msg: "Подписка на рассылку Брусинка партнеры<br>",
          source_id: 13,
          assigned_by_id: 78,
        })
        .then(() => {
          this.UIkit.modal("#success-modal").show();
          this.name = "";
          this.phone = "";
          this.email = "";
        });
    },
  },
};
</script>

<style>
.footer-background {
  background-image: url("../assets/img/footer.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.footer__form-conatin {
  background-color: #fff;
}

.footer__footer-title {
  font-size: 23px;
}
</style>
