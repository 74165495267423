<template>
  <div class="uk-container uk-container-expand">
    <div class="uk-container">
      <div class="uk-width-1-1 uk-margin-medium">
        <div
          class="uk-background-cover uk-height-medium uk-panel uk-flex uk-flex-left uk-flex-bottom"
        >
          <p class="banner background-text">
            <span class="bigger">РАБОТА С ПАРТНЕРАМИ</span> <br />
            <span class="small"
              >мы обеспечиваем возможность максимально прозрачно<br />
              и просто зарабатывать вместе с нами</span
            >
          </p>
        </div>
      </div>

      <div class="uk-grid-small uk-margin-large uk-margin-remove-top">
        <div class="uk-width-1-1">
          <button
            class="uk-button uk-button-default uk-align-center green-button radius-button"
            uk-toggle="target: #modal-consult"
          >
            Уведомить о консультации
          </button>
        </div>
        <div
          class="uk-width-5-6 uk-text-center uk-align-center uk-margin-large-top uk-margin-medium-bottom"
        >
          <p class="content-title uk-margin-medium">
            Как стать партнером с Брусинка
          </p>
          <p>
            Чтобы стать лучшим в своем деле, развить компетенции и повысить
            стоимость своих услуг, подайте заявку на участие в партнерской
            программе. Также, если вы хотите посещать специальные партнерские
            экскурсии и получать информацию о наших объектах, заполните форму
            ниже
          </p>
        </div>

        <div class="uk-width-1-1 uk-text-center">
          <span
            class="icon-arrow green"
            uk-icon="icon:chevron-double-right; ratio: 4"
          ></span>
        </div>

        <div class="uk-flex uk-width-2-3@m uk-width-1-1@s uk-align-center">
          <div class="uk-text-center uk-width-1-2@m uk-width-1-1@s">
            <p
              class="content-title subscribe-button"
              :class="{
                disabled: !subscribeContract,
                checked: subscribeContract,
              }"
              @click="subscribeContract = !subscribeContract"
            >
              Заключить договор
            </p>
          </div>
          <div
            class="uk-text-center uk-width-1-2@m uk-width-1-1@s uk-margin-small-left"
          >
            <p
              class="content-title subscribe-button"
              :class="{
                disabled: !subscribeMailing,
                checked: subscribeMailing,
              }"
              @click="subscribeMailing = !subscribeMailing"
            >
              Подписка на рассылку
            </p>
          </div>
        </div>

        <div
          class="uk-grid-small uk-width-2-3 uk-align-center uk-padding-remove"
          uk-grid
        >
          <div class="grid-small uk-width-1-1 uk-margin-remove" uk-grid>
            <div class="uk-width-1-2@m uk-width-1-1@s uk-padding-small">
              <input
                class="uk-input"
                v-model="agency_name"
                type="text"
                placeholder="Наименование партнера"
                :class="{
                  'uk-form-danger': !isValidContract && !!!agency_name,
                }"
              />
            </div>
            <div
              class="uk-width-1-2@m uk-width-1-1@s uk-padding-small uk-margin-remove"
            >
              <input
                class="uk-input"
                v-model="name"
                type="text"
                placeholder="ФИО"
                :class="{ 'uk-form-danger': !isValidContract && !!!name }"
              />
            </div>
            <div
              class="uk-width-1-2@m uk-width-1-1@s uk-padding-small uk-margin-remove"
            >
              <input
                class="uk-input"
                v-model="email"
                type="text"
                placeholder="E-mail"
                :class="{ 'uk-form-danger': !isValidContract && !!!email }"
              />
            </div>
            <div
              class="uk-width-1-2@m uk-width-1-1@s uk-padding-small uk-margin-remove"
            >
              <input
                class="uk-input"
                v-model="phone"
                type="text"
                placeholder="Телефон"
                :class="{ 'uk-form-danger': !isValidContract && !!!phone }"
              />
            </div>
          </div>
        </div>
        <div class="uk-width-1-1 uk-margin-large-bottom">
          <button
            class="uk-button uk-button-default uk-align-center green-button"
            :disabled="!subscribeContract && !subscribeMailing"
            @click="contractForm"
          >
            Отправить
          </button>
        </div>
        <div
          class="uk-width-5-6 uk-text-center uk-align-center uk-margin-large"
        >
          <p class="content-title uk-margin-medium">
            с нашей партнерской программой вы также можете получать:
          </p>
          <div class="uk-child-width-1-3@m uk-grid-small uk-grid-match" uk-grid>
            <div>
              <div class="uk-card uk-card-default uk-card-body">
                <img src="../assets/icons/bonus.png" alt="" />
                <p class="uk-text-bold">Бонусы</p>
              </div>
            </div>
            <div>
              <div class="uk-card uk-card-default uk-card-body">
                <img src="../assets/icons/sertificates.png" alt="" />
                <p class="uk-text-bold">Сертификаты</p>
              </div>
            </div>
            <div>
              <div class="uk-card uk-card-default uk-card-body">
                <img src="../assets/icons/surprise.png" alt="" />
                <p class="uk-text-bold">Подарки</p>
              </div>
            </div>
          </div>
        </div>
        <div
          class="uk-width-1-1 uk-width-1-6@m uk-text-center uk-align-center uk-margin-large"
        >
          <p class="content-title uk-margin-medium">наши акции</p>
        </div>
      </div>
    </div>
    <div
      class="uk-container uk-container-expand uk-padding-large uk-padding-remove-top"
    >
      <div
        class="uk-position-relative uk-visible-toggle"
        tabindex="-1"
        uk-slider="finite: true, sets: true"
      >
        <ul
          class="uk-slider-items uk-child-width-1-1@s uk-child-width-1-3@m uk-grid"
        >
          <li>
            <div class="uk-panel">
              <img src="../assets/img/first.jpg" alt="" />
              <div class="uk-position-bottom uk-panel">
                <p
                  class="uk-text-center uk-text-bold uk-text-uppercase uk-padding-small slider-text"
                >
                  скидка 10%<br />
                  на проектирование
                </p>
              </div>
            </div>
          </li>
          <li>
            <div class="uk-panel">
              <img src="../assets/img/second.png" alt="" />
              <div class="uk-position-bottom uk-panel">
                <p
                  class="uk-text-center uk-text-bold uk-text-uppercase uk-padding-small slider-text"
                >
                  вознагрождение девелоперов
                </p>
              </div>
            </div>
          </li>
          <li>
            <div class="uk-panel">
              <img src="../assets/img/third.png" alt="" />
              <div class="uk-position-bottom uk-panel">
                <p
                  class="uk-text-center uk-text-bold uk-text-uppercase uk-padding-small slider-text"
                >
                  чердачная лестница в подарок
                </p>
              </div>
            </div>
          </li>
        </ul>

        <a
          class="uk-position-center-left uk-position-small uk-hidden-hover"
          href="#"
          uk-slidenav-previous
          uk-slider-item="previous"
        ></a>
        <a
          class="uk-position-center-right uk-position-small uk-hidden-hover"
          href="#"
          uk-slidenav-next
          uk-slider-item="next"
        ></a>
        <ul class="uk-slider-nav uk-dotnav uk-flex-center uk-margin"></ul>
      </div>
    </div>

    <contacts-component></contacts-component>

    <div class="uk-container uk-padding-large ">
      <div class="uk-width-1-1 uk-text-center uk-margin-remove" uk-grid>
        <p
          class="content-title uk-margin-medium uk-width-1-1 uk-padding-remove uk-margin-remove"
        >
          документы для партнеров
        </p>
      </div>
      <div class="uk-flex uk-flex-center" uk-grid>
        <div class="uk-width-auto@m uk-width-1-1@s uk-card">
          <a
            href="https://disk.yandex.ru/d/ei59yM2uOcWEmg"
            target="_blank"
            class="uk-button uk-button-default uk-align-center green-button uk-margin-remove-bottom"
          >
            регламенты работы
          </a>
        </div>
        <div class="uk-width-auto@m uk-width-1-1@s uk-card">
          <a
            href="https://disk.yandex.ru/d/9GHropcM8aHXRw"
            target="_blank"
            class="uk-button uk-button-default uk-align-center green-button uk-margin-remove-bottom"
          >
            полезные материалы
          </a>
        </div>
        <div class="uk-width-auto@m uk-width-1-1@s uk-card">
          <a
            href="https://disk.yandex.ru/d/AT-9EutGF8CGIg"
            target="_blank"
            class="uk-button uk-button-default uk-align-center green-button uk-margin-remove-bottom"
          >
            шаблоны договоров
          </a>
        </div>
      </div>
    </div>

    <consult-modal></consult-modal>
    <success-modal></success-modal>
  </div>
</template>

<script>
import successModal from "./SuccessModal.vue";
import consultModal from "./ConsultModal.vue";
import contactsComponent from "./Contacts.vue";
import mixin from "../mixins/index";

export default {
  mixins: [mixin],
  data() {
    return {
      agency_name: "",
      name: "",
      email: "",
      phone: "",

      subscribeContract: true,
      subscribeMailing: true,

      isValidContract: true,
    };
  },
  watch: {
    phone(val) {
      this.phoneMask(val, "phone");
    },
  },
  computed: {
    validContractForm() {
      return !(!this.name || !this.email || !this.agency_name || !this.phone);
    },
  },
  methods: {
    // checkPhone(value, field) {
    //   if (value.startsWith("8")) this[field] = this[field].substring(1);
    //   else if (value.startsWith("+7")) this[field] = this[field].substring(2);
    //   this[field] = this[field].replace(/[\s-()]/gi, "");

    //   if (this[field].length > 10) {
    //     this[field] = this[field].slice(0, 10);
    //   }
    // },
    contractForm() {
      if (!this.validContractForm) {
        this.isValidContract = false;
        return;
      }
      this.isValidContract = true;

      let form = this.subscribeContract
        ? "Заключить договор. АН"
        : "Подписка на рассылку";
      this.request({
        form: `${form} ${this.agency_name}`,
        name: this.name,
        email: this.email,
        phone: this.phone,
        msg: `
                Подписка на рассылку Брусинка партнеры<br>
                Наименование партнера: ${this.agency_name};<br>
                ФИО: ${this.name};<br>
                E-mail: ${this.email};<br>
                Телефон: ${this.phone};<br>
                Заключить договор: ${this.subscribeContract ? "Да" : "Нет"};<br>
                Подписка на рассылку: ${
                  this.subscribeMailing ? "Да" : "Нет"
                };<br>
                `,
        source_id: 13,
        assigned_by_id: 78,
      }).then(() => {
        this.UIkit.modal("#success-modal").show();
        this.name = "";
        this.email = "";
        this.phone = "";
      });
    },
  },
  components: {
    successModal,
    consultModal,
    contactsComponent,
  },
};
</script>

<style>
.uk-background-cover {
  background-image: url("../assets/img/Partners_673_379.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
}

.background-text {
  color: #fff;
}

.banner {
  padding: 30px;
}
.content-title {
  font-size: 20px;
  text-transform: uppercase;
  font-weight: bold;
  position: relative;
}
.content-title:after {
  content: "";
  display: block;
  position: absolute;
  height: 3px;
  width: 100px;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #7f2f44;
  transition: 0.2s;
}
.content-title.disabled:after {
  width: 0%;
}
.subscribe-button {
  border: 1px solid #dcdcdc;
  border-radius: 30px;
  -moz-user-select: none;
  -khtml-user-select: none;
  user-select: none;
  box-shadow: 0px 2px 0 rgba(1, 1, 1, 0.05), 2px 4px 6px rgba(1, 1, 1, 0.02);
}
.subscribe-button.checked {
  color: #FFF;
      background: linear-gradient(rgb(127 47 68) 50%, #fff);
      box-shadow: inset 0px 2px 0 rgb(1 1 1 / 5%), inset 2px 4px 6px rgb(1 1 1 / 2%);
}

.subscribe-button:hover {
  background-color: #f5f5f5;
  box-shadow: 1px 1px 2px rgba(#fff, 0.2);
  cursor: pointer;
}
.bigger {
  font-size: 30px;
  font-weight: bold;
}
.small {
  font-size: 20px;
  font-weight: bold;
}
.icon-arrow {
  transform: rotate(90deg);
}
.uk-slider-items img {
  filter: brightness(0.7);
}
.slider-text {
  font-size: 30px;
  color: #fff;
}

.phone {
  color: #7f2f44;
  font-size: 20px;
  font-weight: bold;
}
.photo {
  border-radius: 50%;
}

.contacts a {
  color: #666;
}

.name {
  font-weight: bold;
  font-size: 19px;
}
</style>
