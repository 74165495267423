<template>
  <div class="uk-container">
    <nav class="uk-navbar uk-navbar-container uk-hidden@m uk-padding-small">
      <div class="uk-navbar-left">
        <a href="https://xn--80abvioqhp.xn--p1ai/"
          ><img src="../assets/img/logo.png" width="100px"
        /></a>
      </div>
      <div class="uk-navbar-right">
        <a
          class="uk-navbar-toggle"
          uk-navbar-toggle-icon
          href="#mobile-nav"
          uk-toggle
        ></a>
      </div>
    </nav>

    <div class="uk-visible@m">
      <nav
        class="uk-margin-remove uk-padding-small nav-top "
        uk-navbar="mode: click"
      >
        <div class="uk-navbar-right">
          <ul class="uk-subnav uk-margin-remove-bottom">
            <li>
              <a
                href="https://xn--80abvioqhp.xn--p1ai/o-kompanii/"
                target="_blank"
                class="uk-padding-remove"
                >О компании</a
              >
            </li>
            <li>
              <a
                href="https://lk.vevanta.com/auth"
                target="_blank"
                class="uk-padding-remove"
                >Личный кабинет</a
              >
            </li>
          </ul>
        </div>
      </nav>
      <div class="uk-container uk-margin-remove" uk-grid>
        <div class="uk-width-expand uk-padding-remove">
          <a href="https://xn--80abvioqhp.xn--p1ai"><img style="max-height: 109px;" src="../assets/img/logo.png"/></a>
        </div>
        <div
          class="uk-width-expand uk-align-right uk-text-right uk-grid-column-small uk-margin-remove uk-width-1-6"
        >
          <p class="uk-margin-remove">Г. Тюмень,</p>
          <p class="uk-margin-remove">ул. Железнодорожная 54/3</p>
        </div>
        <div
          class="uk-align-right uk-text-right uk-grid-column-small uk-margin-remove uk-width-1-6"
        >
          <p class="uk-margin-remove">Режим работы:</p>
          <p class="uk-margin-remove">Пн-Сб 09:00-19:00</p>
          <p class="uk-margin-remove">
            <a class="green uk-text-bold" href="tel:+73452596041"
              >+7 (3452) 59-60-41</a
            >
          </p>
        </div>
        <div
          class="uk-align-right uk-text-right uk-grid-column-small uk-margin-remove uk-width-1-6 logos"
        >
          <p class="uk-margin-small">
            <a
              href="https://vk.com/stroitelstvo_domov_i_kottedjey"
              target="_blank"
              ><img src="../assets/img/vkontakte.png" height="20px" width="20px"
            /></a>
          </p>
          <p class="uk-margin-small">
            <a href="https://www.instagram.com/vevanta72" target="_blank"
              ><img src="../assets/img/instagram.png" height="20px" width="20px"
            /></a>
          </p>
          <p class="uk-margin-small">
            <a
              href="https://www.youtube.com/channel/UCRytU0cHs-IZN0dsBZW6Shw"
              target="_blank"
              ><img src="../assets/img/youtube.png" height="20px" width="20px"
            /></a>
          </p>
        </div>
      </div>
      <nav uk-navbar="mode: click">
        <div class="uk-navbar-left">
          <ul class="uk-navbar-nav nav-bottom">
            <li>
              <a
                href="https://xn--80abvioqhp.xn--p1ai/uslugi/"
                target="_blank"
                class="uk-padding-small"
                >Услуги</a
              >
            </li>
            <li>
              <a
                href="https://xn--80abvioqhp.xn--p1ai/projects/"
                target="_blank"
                class="uk-padding-small"
                >Каталог проектов</a
              >
            </li>
            <li>
              <a
                href="https://life.vevanta.com/"
                target="_blank"
                class="uk-padding-small"
                >КП "Веванта Лайф"</a
              >
            </li>
            <li>
              <a
                href="https://xn--80abvioqhp.xn--p1ai/uslugi/proektirovanie/"
                target="_blank"
                class="uk-padding-small"
                >Заказать проект</a
              >
            </li>
            <li>
              <a
                href="https://xn--80abvioqhp.xn--p1ai/#portfolio"
                target="_blank"
                class="uk-padding-small"
                >Портфолио</a
              >
            </li>
            <li>
              <a
                href="https://xn--80abvioqhp.xn--p1ai/uslugi/ceny-na-stroitelstvo-domov/"
                target="_blank"
                class="uk-padding-small"
                >Цены</a
              >
            </li>
            <li>
              <a
                href="https://xn--80abvioqhp.xn--p1ai/kontakty/"
                target="_blank"
                class="uk-padding-small"
                >Контакты</a
              >
            </li>
          </ul>
        </div>
      </nav>
    </div>

    <div id="mobile-nav" uk-offcanvas>
      <div class="uk-offcanvas-bar uk-background-default">
        <button class="uk-offcanvas-close" type="button" uk-close></button>
        <div class="uk-width-expand uk-padding-remove">
          <a href="https://xn--80abvioqhp.xn--p1ai/"><img src="../assets/img/logo.png"/></a>
          <p class="uk-margin-remove-top">Тюмень,Железнодорожная 54/3</p>
          <p>
            <span class="uk-margin-small-right green" uk-icon="receiver"></span
            ><a class="green" href="tel:+73452596041"
              >+7 (3452) 59-60-41</a
            >
          </p>
          <ul class="uk-nav uk-nav-default uk-list uk-list-divider">
            <li>
              <a
                href="https://xn--80abvioqhp.xn--p1ai/uslugi/"
                target="_blank"
                class="uk-padding-small"
                >Услуги</a
              >
            </li>
            <li>
              <a
                href="https://xn--80abvioqhp.xn--p1ai/projects/"
                target="_blank"
                class="uk-padding-small"
                >Каталог проектов</a
              >
            </li>
            <li>
              <a
                href="https://xn--80abvioqhp.xn--p1ai/uslugi/proektirovanie/"
                target="_blank"
                class="uk-padding-small"
                >Заказать проект</a
              >
            </li>
            <li>
              <a
                href="https://xn--80abvioqhp.xn--p1ai/#portfolio"
                target="_blank"
                class="uk-padding-small"
                >Портфолио</a
              >
            </li>
            <li>
              <a
                href="https://xn--80abvioqhp.xn--p1ai/uslugi/ceny-na-stroitelstvo-domov/"
                target="_blank"
                class="uk-padding-small"
                >Цены</a
              >
            </li>
            <li>
              <a
                href="https://xn--80abvioqhp.xn--p1ai/kontakty/"
                target="_blank"
                class="uk-padding-small"
                >Контакты</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style>
.uk-subnav li a {
  font-size: 0.75em !important;
}
.uk-subnav > * > :first-child {
  color: #000 !important;
}
.nav-top {
  padding-right: 0 !important;
}

.nav-top a {
  font-size: 11px;
}

.uk-navbar-nav.nav-bottom li:first-child a {
  padding-left: 0 !important;
}

.uk-navbar-nav.nav-bottom a {
  font-weight: bold;
}

.logos a {
  opacity: 0.9;
  filter: grayscale(1);
  transition: filter 0.3s, opacity 0.3s;
}

#mobile-nav p,
#mobile-nav li,
#mobile-nav a,
hr {
  color: #666;
  border-color: #666;
}
</style>
