<template>
  <div id="modal-consult" uk-modal ref="modal">
    <div class="uk-modal-dialog uk-modal-body">
      <h2 class="uk-modal-title uk-text-center">
        Информация о проведенной консультации
      </h2>
      <button class="uk-modal-close-default" type="button" uk-close></button>
      <h4>Специалист</h4>
      <div class="uk-margin ">
        <input
          v-model="modal_agency_name"
          class="uk-input"
          type="text"
          placeholder="Наименование партнера"
        />
      </div>
      <div class="uk-margin ">
        <input
          v-model="modal_agent_name"
          class="uk-input"
          type="text"
          placeholder="ФИО специалиста"
        />
      </div>
      <div class="uk-margin ">
        <input
          v-model="modal_agent_phone"
          name="phone"
          class="uk-input"
          type="text"
          placeholder="+7 (999) 999-99-99"
        />
      </div>
      <div class="uk-margin ">
        <input
          v-model="modal_agent_email"
          name="modal_agent_email"
          class="uk-input"
          type="text"
          placeholder="E-mail"
        />
      </div>
      <h4>Заказчик</h4>
      <div class="uk-margin ">
        <input
          v-model="modal_client_name"
          class="uk-input"
          type="text"
          placeholder="ФИО"
        />
      </div>
      <div class="uk-margin ">
        <input
          v-model="modal_client_phone"
          name="modal_client_phone"
          class="uk-input"
          type="text"
          placeholder="+7 (999) 999-99-99"
        />
      </div>
      <div class="uk-margin uk-grid-small uk-child-width-auto uk-grid">
        <label
          ><input
            v-model="hasGround"
            class="uk-checkbox"
            type="checkbox"
            checked
          />
          Наличие земельного участка</label
        >
      </div>
      <div class="uk-grid-small" uk-grid>
        <p class="uk-width-1-1">Стоимость</p>
        <div class="uk-width-1-2">
          <input
            v-model.number="price_from"
            class="uk-input"
            type="text"
            placeholder="От"
          />
        </div>
        <div class="uk-width-1-2">
          <input
            v-model.number="price_to"
            class="uk-input"
            type="text"
            placeholder="До"
          />
        </div>
      </div>
      <div class="uk-margin ">
        <textarea
          v-model="description"
          class="uk-textarea"
          rows="3"
          placeholder="Краткое описание проекта"
        ></textarea>
      </div>
      <div class="uk-margin">
         <label
          ><input
            class="uk-checkbox"
            type="checkbox"
            checked
            v-model="isConsultation"
          /> Отправить на консультацию КП "Веванта Лайф
          </label
        >
      </div>
      <div class="uk-margin">
        <button
          class="uk-button uk-button-default uk-align-center green-button uk-margin-bottom"
          @click="consultationForm"
          :disabled="!isAccept"
        >
          Отправить
        </button>
        <label class="text-accept"
          ><input
            class="uk-checkbox"
            type="checkbox"
            checked
            v-model="isAccept"
          /> 
          Нажимая кнопку "Отправить", вы соглашаетесь вы соглашаетесь на
          обработку ваших
          <a
            href="https://vevanta.com/politika-konfidencialnosti"
            target="_blank"
            class="green"
          >
            персональных данных</a
          ></label
        >
      </div>
    </div>
  </div>
</template>

<script>
import mixin from "../mixins/index";

export default {
  mixins: [mixin],
  data() {
    return {
      modal_agency_name: "",
      modal_agent_name: "",
      modal_agent_phone: "",
      modal_agent_email: "",
      modal_client_name: "",
      modal_client_phone: "",
      hasGround: false,
      price_from: "",
      price_to: "",
      description: "",

      isAccept: true,
      isConsultation: false
    };
  },
  watch: {
    modal_agent_phone(val) {
      this.phoneMask(val, "modal_agent_phone");
    },
    modal_client_phone(val) {
      this.phoneMask(val, "modal_client_phone");
    },
  },
  methods: {
    consultationForm() {
      this.request({
        form: `Информация о консультации. АН ${this.modal_agency_name}`,
        name: this.modal_client_name,
        email: this.modal_agent_email,
        phone: this.modal_client_phone,
        msg: `
              Уведомить о консультации. Веванта Партнеры<br>
              Наименование партнера: ${this.modal_agency_name};<br>
              Специалист: ${this.modal_agent_name};<br>
              Телефон специалиста: <a href="tel:${this.modal_agent_phone}">${this.modal_agent_phone}</a>;<br>
              Электронная почта специалиста: <a href="mailto:${this.modal_agent_email}">${this.modal_agent_email}</a>;<br>
              Заказчик: ${this.modal_client_name};<br>
              Телефон заказчика: <a href="tel:${this.modal_client_phone}">${this.modal_client_phone}</a>;<br>
              Наличие земельного участка: ${this.hasGround ? "Да" : "Нет"};<br>
              Стоимость: от ${this.price_from} до ${this.price_to};<br>
              Краткое описание: ${this.description};<br>
              Отправить на консультацию КП "Веванта Лайф: ${this.isConsultation ? "Да" : "Нет"};<br>
              `,
        source_id: 13,
        assigned_by_id: 78,
      }).then(() => {
        this.UIkit.modal(this.$refs.modal).hide();
        this.UIkit.modal("#success-modal").show();
        this.modal_agency_name = "";
        this.modal_agent_name = "";
        this.modal_agent_email = "";
        this.modal_agent_phone = "";
        this.modal_client_name = "";
        this.modal_client_phone = "";
        this.hasGround = false;
        this.price_from = "";
        this.price_to = "";
        this.description = "";
      });
    },
  },
};
</script>

<style scoped>
.text-accept {
  font-size: 14px;
}
</style>
