export default {
  data() {
    return {
      formatPhone: null,
    }
  },
  methods: {
    phoneMask(val, field) {
      let currentPhone = "";
      this.formatPhone = val.replace(/[\D]/gi, "");
      if (this.formatPhone.length >= 0) {
        if (
          this.formatPhone.startsWith("7") ||
          this.formatPhone.startsWith("8")
        )
          this.formatPhone = this.formatPhone.slice(1);
        currentPhone += "+7";
      }
      if (this.formatPhone.length >= 1) {
        currentPhone += `(${this.formatPhone.slice(0, 3)}`;
      }
      if (this.formatPhone.length > 3) {
        currentPhone += `) ${this.formatPhone.slice(3, 6)}`;
      }
      if (this.formatPhone.length > 6) {
        currentPhone += `-${this.formatPhone.slice(6, 8)}`;
      }
      if (this.formatPhone.length > 8) {
        currentPhone += `-${this.formatPhone.slice(8, 10)}`;
      }
      if (this.formatPhone.length == 10) {
        this[field] = this[field].slice(0, 10);
      }
      this[field] = currentPhone;
    },

    request(fields) {
      return this.$axios.post("mail/vevanta_partner", fields);
    },
  }
}
